import React, { useState } from 'react';
import { DateTime } from 'luxon';
import "./scholarship-card.scss";
import Calendar from '../SVG/Calendar';
import FormatNumber, { floatFormatter } from '../../Utility/FormatNumber';
import { MAIN_CONFIG } from '../../../config/main';
import Close from '../../Components/SVG/Close';
import { parseHtml, formatDeadlineDate, formatOpenDate, handleRedirectHrefUrl } from '../../Utility/HelperFunctions';
import ImagePlaceholderForCard from '../../Components/SVG/ImagePlaceholderForCard';
import ProgressCompleteCellType from '../Table/CellTypes/ProgressCompleteCellType/ProgressCompleteCellType';
import ReactTimeago from 'react-timeago';
import Success from '../SVG/Success';
import DrawnArrow from '../SVG/DrawnArrow';
import CTAButton from '../CTAButton/CTAButton';
import PropTypes from 'prop-types'
import { getDynamicClassName } from '../../Utility/ApplicationFormUtility';
import CTAImageTag from '../CTAImageTag/CTAImageTag';

export const ScholarshipCard = ({
    maximumFundAmount,
    openDate,
    statusScholarship,
    setSubmitted = () => { },
    id,
    color,
    logo,
    scholarshipHeaderLogo,
    title,
    excerpt,
    tags,
    award,
    due_date,
    url,
    showCurrencySymbol,
    showFundAmount,
    scholarshipFormType,
    scholarshipFundAmount,
    totalDonatedAmount,
    lastDonatedAmount,
    donationProgress,
    showDonorScholarshipTile,
    autoApplyScholarship,
    open_date_converted,
    showOpenDate = false,
    sponserLogo,
    sponcerImage,
    headerRedirectionUrl,
    sponserDescription,
    elementsProgramUrl = '', 
    isElementsProgram = false 

}) => {

    function getFormattedDeadlineDate(date) {
        if (date) {
            return formatDeadlineDate(new Date(date));
        } else {
            return '';
        }
    }

    function getFormattedOpenDate(openDate) {
        if (openDate) {
            return formatOpenDate(new Date(openDate));
        } else {
            return '';
        }
    }

    const getConvertedColor = (primaryColor, color) => {
        if (primaryColor == '#ffffff') {
            return '#000000';
        } else {
            return color;
        }
    }

    const formattedDate = getFormattedDeadlineDate(due_date);
    const formattedOpenDate = getFormattedOpenDate(open_date_converted);
    const [activeTab, setActiveTab] = useState(false);
    let sponsorForm = scholarshipFormType === "Advertisement"
    let primaryColorConvert = color?.toLowerCase()
    let showDonationArr = showDonorScholarshipTile?.split(";")
    let donationForm = scholarshipFormType === "Donation Form"
    const remainingAmount = scholarshipFundAmount - totalDonatedAmount
    const qualifiedMessage = "Congratulations, you qualified for this program, no action needed!"
    const headerClassName = getDynamicClassName(sponsorForm, "scholarship-card sponsor-card", "scholarship-card")
    const convertColor = getConvertedColor(primaryColorConvert, color)

    const handleLeave = (e, value) => {
        setActiveTab(false)
        e.preventDefault();
    }

    const renderTags = (id) => tags.map((tag, i) => {
        if (i < id) {
            return <span key={i} className="scholarship-card__tag background-color__light-grey BodySmallMediumBlack">{tag}</span>
        }
    });

    //Handle Card Click redirection with shallow
    const handleCardClick = (e) => {
        e.preventDefault();
        if (sponsorForm && headerRedirectionUrl !== '') {
            window.open(headerRedirectionUrl)
        } else if (isElementsProgram){
            handleRedirectHrefUrl(elementsProgramUrl)
        } else {
            setSubmitted(true)
            handleRedirectHrefUrl(url);
        }
    }

    const getScholorshipCardHeaderColor = () => {
        if (color) {
            return { borderTop: `15px solid ${convertColor}`, borderRadius: '5px' }
        }
        return { borderTop: `15px solid #008067`, borderRadius: '5px' }
    }
    const handleKeyDown = (event, callBack) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            callBack(); // Call the onClick function when Enter is pressed
        }
    };

    const scholarshipSubmitByDate = () => {
        if (!autoApplyScholarship && !donationForm) {
            if (!(new Date(openDate) > new Date() && statusScholarship === 'Accepting Applications') && due_date) {
                return (
                    <div className="scholarship-card__date-section">
                        <div className="scholarship-card__date-calendar-section">
                            <Calendar
                                width={20}
                                height={20}
                                ariaLabel="Scholarship Status"
                                ariaLabelDescription=""
                            />
                            <div className="scholarship-card__date-label-section" style={{ display: 'flex' }}>
                                Submit by:
                                <div className='scholarship-card__date-time-section' style={{ marginLeft: '14px' }}>{formattedDate}</div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return null;
    };

    const formatMaximumFundAmount = (amount, showCurrencySymbol) => {
        if (amount) {
            const formattedAmount = FormatNumber(floatFormatter(amount));
            return `Up to ${showCurrencySymbol ? '$' : ''}${formattedAmount}  `;
        } else {
            return '';
        }
    };

    const renderAwardSection = () => {
        if (award) {
            return (
                <div>
                    <span className='scholarship-card__award-container__award-text'>Award Amount :</span>
                    <span className="scholarship-card__award-container__award-amount"> {showCurrencySymbol && '$'}{showFundAmount && FormatNumber(floatFormatter(award))}</span>
                    <img className='scholarship-card__award-container__award-amount__icon' src="https://mykldexportsprod.blob.core.windows.net/userreports/imports/classic/o67dsf2m2thfpxmvjngp.svg" alt='Award Logo' />
                </div>
            );
        }
        if (scholarshipFormType !== "Donation Form") {
            return (
                <div>
                    <span className='scholarship-card__award-container__award-text'>Award Amount :</span>
                    <span className="scholarship-card__award-container__award-amount"> {formatMaximumFundAmount(maximumFundAmount, showCurrencySymbol)}</span>
                    <img className='scholarship-card__award-container__award-amount__icon' src="https://mykldexportsprod.blob.core.windows.net/userreports/imports/classic/o67dsf2m2thfpxmvjngp.svg" alt='Award Logo' />
                </div>
            );
        }
        return null;
    };

    const renderAwardContainer = () => {
        if (!autoApplyScholarship && !donationForm && showFundAmount && (award || maximumFundAmount)) {
            return (
                <div className='scholarship-card__award-container'>
                    {renderAwardSection()}
                </div>
            );
        }
        return null;
    };


    const renderSponsorForm = () => {
        if (sponsorForm) {
            return (
                <>
                    <div className="scholarship-card-sponsor-form-top">
                        <div className='scholarship-card-sponsor-form-top__leading'>
                            <a className="scholarship-card-sponsor-form-top__leading__logo">
                                {
                                    sponserLogo
                                        ?
                                        <CTAImageTag className="scholarship-card__logo" data={sponserLogo} title={`${title} logo`} />
                                        :
                                        <ImagePlaceholderForCard
                                            id={id}
                                            className=""
                                            height={100}
                                            width={100}
                                            ariaLabel="default Logo"
                                            ariaLabelDescription=""
                                        />
                                }
                            </a>
                        </div>
                        <div className="scholarship-card-sponsor-form-top__leading__label">
                            Sponsored
                        </div>
                    </div>
                    <div className="scholarship-card-sponsor-form-title H6Desktop">
                        {title}
                    </div>
                    {sponserDescription && <div className="scholarship-card__excerpt">
                        <p className="color__black BodySmallRegular">
                            {parseHtml(excerpt)}
                        </p>
                    </div>}
                    <div className="scholarship-card-sponsor-form-description">
                        <div className="scholarship-card-sponsor-form-description__excerpt">
                            {
                                title?.length < 60 &&
                                <p className="color__black BodySmallRegular">
                                    {parseHtml(sponserDescription)}
                                </p>
                            }
                        </div>
                        <div className="scholarship-card-sponsor-form-description__sponser-image">
                            {
                                sponcerImage
                                    ?
                                    <CTAImageTag className="scholarship-card-sponsor-form-description__sponser-image__scholarship-card-image" data={sponcerImage} title={`${title} Image`} />
                                    :
                                    <ImagePlaceholderForCard
                                        id={id}
                                        className=""
                                        height={100}
                                        width={100}
                                        ariaLabel="default Logo"
                                        ariaLabelDescription=""
                                    />
                            }
                        </div>
                    </div>
                    <div className="scholarship-card-sponsor-form-tags">
                        <div className="scholarship-card-sponsor-form-tags__single-tag">
                            Special offer
                        </div>
                    </div>
                    <div className="scholarship-card-sponsor-form-learn-more-container">
                        <CTAButton id='task-page__cta' type='tertiary' onKeyDown={(e) => handleKeyDown(e, () => handleCardClick(e))}>
                            Learn more
                        </CTAButton>
                    </div>
                </>
            );
        }

        // Return null if sponsorForm condition is not met
        return null;
    };

    const renderScholarshipCards = () => {
        return (
            <>
                <div className="scholarship-card__top">
                    <a className="scholarship-card__top-leading">
                        {
                            scholarshipHeaderLogo || logo
                                ?
                                <CTAImageTag className="scholarship-card__logo" data={scholarshipHeaderLogo ? scholarshipHeaderLogo : logo} title={`${title} logo`} />
                                :
                                <ImagePlaceholderForCard
                                    id={id}
                                    className=""
                                    height={100}
                                    width={100}
                                    ariaLabel="default Logo"
                                    ariaLabelDescription=""
                                />
                        }
                    </a>
                </div>

                <div className="scholarship-card__title H6Desktop"> {title}</div>

                {autoApplyScholarship ? (
                    <div className="scholarship-card__excerpt">
                        <p className="color__black BodySmallRegular">
                            {parseHtml(qualifiedMessage)}
                        </p>
                    </div>
                ) : (
                    <div className="scholarship-card__excerpt">
                        <p className="color__black BodySmallRegular">
                            {parseHtml(excerpt)}
                        </p>
                    </div>
                )}

                {scholarshipFormType === "Donation Form" && <div className="scholarship-card__donations-progress-bar">

                    <div className='scholarship__tile__content'>
                        {
                            donationForm &&
                            <div className='donation-goal-scholarship-tile BodySmallRegular'>
                                <span class="goal__scholarship">Goal :</span>  <span>${scholarshipFundAmount !== null ? FormatNumber(floatFormatter(scholarshipFundAmount)) : 0}</span>
                                <img src="https://mykldexportsprod.blob.core.windows.net/userreports/imports/classic/ysw2vyroxgp4zfgn4xjf.svg" alt='Donation Logo' />
                            </div>
                        }

                        {<div className="scholarship-card__donations-progress-bar">

                            {scholarshipFormType === "Donation Form" && showDonationArr?.includes("Show Fund Progress") && <div className='scholarship-card__donations-progress-bar__progress-container'>
                                <ProgressCompleteCellType
                                    value={donationProgress !== null ? donationProgress + "%" : 0 + "%"}
                                    barBackgroundColor="barBackgroundColor"
                                    barProgressColor={primaryColorConvert == '#ffffff' ? '#000000' : primaryColorConvert}
                                />
                            </div>}
                            {showDonationArr?.includes('Show Fund Amount') &&
                                <div
                                    className='scholarship-card__donations-progress-bar__donations-amount-status BodySmallRegular'
                                    style={{ fontSize: '11px' }}
                                >
                                    <span className="">${totalDonatedAmount !== null ? FormatNumber(floatFormatter(totalDonatedAmount)) : 0} Donated</span>  {remainingAmount > 0 && <span>${scholarshipFundAmount !== null ? FormatNumber(floatFormatter(remainingAmount)) : 0} Remaining</span>}
                                </div>}
                        </div>
                        }
                    </div>
                    {showDonationArr?.includes('Show Last Donation Time') && lastDonatedAmount !== null && <div className='scholarship-card__donations-progress-bar__last-donations BodySmallRegular'>
                        <span className="">Last donation</span> <ReactTimeago date={DateTime.fromISO(lastDonatedAmount)} />
                    </div>}
                </div>
                }

                {renderAwardContainer()}

                {scholarshipSubmitByDate()}

                {!(new Date(openDate) > new Date()) && scholarshipFormType !== "Donation Form" && autoApplyScholarship === false && statusScholarship === 'Accepting Applications' &&
                    <div className="scholarship-card__programs-status">
                        <span className="scholarship-card__programs-status__scholarship-status">Accepting Applications</span>
                    </div>
                }

                {autoApplyScholarship === false && scholarshipFormType !== "Donation Form" && statusScholarship === 'Accepting Applications' && !(new Date(openDate) < new Date()) &&
                    <>
                        {showOpenDate == true && <div className="scholarship-card__date-section">
                            <div className="scholarship-card__date-calendar-section">
                                <Calendar
                                    width={20}
                                    height={20}
                                    ariaLabel="Scholarship Status"
                                    ariaLabelDescription=""
                                />
                                <span className="scholarship-card__date-label-section" style={{ display: 'flex' }}>Open:
                                    {(statusScholarship === 'Accepting Applications' && showOpenDate == true) &&
                                        <span style={{ marginLeft: '14px' }}> {formattedOpenDate}</span>
                                    }
                                </span>
                            </div>
                        </div>}
                        <div className="scholarship-card__programs-status">
                            <span className="scholarship-card__programs-status__scholarship-status">Coming Soon</span>
                        </div>
                    </>
                }

                {autoApplyScholarship &&
                    <div className="scholarship-card__date">
                        <div className="scholarship-card__date-calendar">
                            <Success
                                width='40px'
                                height='35px'
                                ariaLabel="Scholarship Status"
                                ariaLabelDescription=""
                                iconColor={MAIN_CONFIG.COLORS.fillGraySuccess}
                            />
                            <span className="scholarship-card__date-label">Qualified</span>
                        </div>
                    </div>
                }

                {autoApplyScholarship === false && !donationForm &&
                    <div className='scholarship-card__footer-button' >
                        <div className='scholarship-card__footer-button__learn-more' onClick={(e) => handleCardClick(e)} style={{ display: "flex", alignItems: "center", marginLeft: '10px' }} tabIndex={0}>Learn more
                            <DrawnArrow className='account-switcher__arrow' color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel={`${title}`} />
                        </div>
                    </div>
                }
            </>
        );
    };

    const renderCards = () => {
        if (sponsorForm) {
            return (renderSponsorForm())
        } else {
            return (
                renderScholarshipCards()
            )
        }
    }

    return (
        <span className="scholarship-card__wrapper-link" key={id} tabIndex={0} aria-label={title} aria-describedby=''>
            <div className={headerClassName} onClick={(e) => handleCardClick(e)} style={getScholorshipCardHeaderColor()} tabIndex={-1} onKeyDown={(e) => handleKeyDown(e, () => handleCardClick(e))}>
                {renderCards()}
            </div>

            <div className={getDynamicClassName(activeTab, "scholarship-card__tags-popup  tags-popup__show", "scholarship-card__tags-popup  tags-popup__hide")}>
                <button  onClick={(e) => handleLeave(e, 2)} className="scholarship-card__tags-popup__closed-btn top-btn">
                    <Close color={MAIN_CONFIG.COLORS.fillRed} />
                </button>
                <div className="scholarship-card__tags">
                    {renderTags(20)}
                </div>
            </div>
        </span>
    );
};

ScholarshipCard.propTypes = {
    maximumFundAmount: PropTypes.object,
    openDate: PropTypes.string,
    statusScholarship: PropTypes.string,
    setSubmitted: PropTypes.func,
    id: PropTypes.string,
    color: PropTypes.object,
    logo: PropTypes.string,
    scholarshipHeaderLogo: PropTypes.object,
    title: PropTypes.string,
    excerpt: PropTypes.string,
    tags: PropTypes.object,
    award: PropTypes.number,
    due_date: PropTypes.string,
    url: PropTypes.string,
    showCurrencySymbol: PropTypes.bool,
    showFundAmount: PropTypes.bool,
    scholarshipFormType: PropTypes.string,
    scholarshipFundAmount: PropTypes.number,
    totalDonatedAmount: PropTypes.object,
    lastDonatedAmount: PropTypes.object,
    donationProgress: PropTypes.object,
    showDonorScholarshipTile: PropTypes.object,
    autoApplyScholarship: PropTypes.bool,
    open_date_converted: PropTypes.string,
    showOpenDate: PropTypes.bool,
    sponserLogo: PropTypes.string,
    sponcerImage: PropTypes.string,
    headerRedirectionUrl: PropTypes.string,
    sponserDescription: PropTypes.string,
    elementsProgramUrl: PropTypes.string,
    isElementsProgram: PropTypes.bool 
};